import {
  Card,
  Divider,
  Drawer,
  IconButton,
  useTheme,
  Tooltip,
} from "@mui/material";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useMemo } from "react";
import "./leaderboardview.scss";

import { CalendarMonth, Close } from "@mui/icons-material";
import EastIcon from "@mui/icons-material/East";
// import TabMenu from "../../components/tabmenu/tabmenu";
import moment from "moment";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ResizableTable from "../../components/dataTable/resizableTable";
import MyDateRangePicker from "../../components/datepicker/myDateRangePicker";
import {
  exportInteractionsTableByHowSolved,
  exportInteractionsTableByWorkload,
  getActiveChannels,
  getAllBuHavingPgUnderUser,
  getCountChart,
  getInteractionsCountByHowSolved,
  getInteractionsCountByWorkload,
  getInteractionsSourceChannelByWorkload,
  getInteractionsTableByHowSolved,
  getInteractionsTableByWorkload,
  getSavingsChart,
} from "../../redux/slices/leaderboardSlice";
import UserIcon from "../../assets/UserIcon";
import Linecharts from "../../components/highcharts/linecharts";
import Barcharts from "../../components/highcharts/barcharts";
import ExportIcon from "../../assets/ExportIcon";
import ExportDeleteIcon from "../../assets/ExportDeleteIcon";

import ChatIcon from "../../assets/ChatIcon";
import SlackIcon from "../../assets/SlackIcon";
import TicketIcon from "../../assets/TicketIcon";
import IconPrediction from "../../assets/IconPrediction";
import DowloadIcon from "../../assets/DowloadIcon";




function Leaderboardview() {
  //local state
  const theme = useTheme();
  const colors = theme.palette;
  const dispatch = useDispatch();
  const [openDate, setOpenDate] = useState(false);
  const [dateLabel, setDateLabel] = useState();
  const [date, setDate] = useState();
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [selectedBu, setSelectedBu] = useState([]);
  const [openTable, setOpenTable] = useState(false);
  const [howSolved, setHowSolved] = useState("");
  const [workload, setWorkload] = useState("");
  const [pagination, setPagination] = useState({ page: 0, pageSize: 10 });
  const [sourceChannels, setSourceChannels] = useState([]);
  const renderIcon = (item) => {
    switch (item) {
      case 'Chat':
        return <ChatIcon />;
      case 'Slack':
        return <SlackIcon />;
      case 'Ticket':
        return <TicketIcon />;
      case 'Resolution Prediction':
        return <IconPrediction />;
      default:
        return null; // Handle any default or unknown cases
    }
  };

  //redux
  const {
    active_channels,
    business_units,
    interactions_table,
    interactions_count,
    savings_chart_details,
    count_chart_details,
    interactions_count_by_workload,
    source_channel_count,
  } = useSelector((state) => state.leaderboardReducer);
  const memoizedActivChannels = useMemo(
    () =>
      active_channels.map((item) => ({
        ...item,
        label: item.name,
        id: item.uuid,
      })),
    [active_channels]
  );
  const memoizedBusinessUnits = useMemo(
    () =>
      business_units.map((item) => ({
        ...item,
        label: item.name,
        id: item.uuid,
      })),
    [business_units]
  );

  //function
  const handleDateChange = (state) => {
    setDateLabel(
      `${moment(state.startDate).format("MM/DD/YYYY")}-${moment(
        state.endDate
      ).format("MM/DD/YYYY")}`
    );
    setDate({
      start_date: moment(state.startDate).format("YYYY-MM-DD"),
      end_date: moment(state.endDate).format("YYYY-MM-DD"),
    });
  };

  const handleChannelChange = (newValue) => {
    setSelectedChannels(newValue.map((channel) => channel.name));
  };

  const handleBuChange = (newValue) => {
    setSelectedBu(newValue.map((bu) => bu.uuid));
  };

  //useEffects
  useEffect(() => {
    dispatch(getActiveChannels());
    dispatch(getAllBuHavingPgUnderUser());
  }, []);

  useEffect(() => {
    setSelectedChannels(active_channels.map((channel) => channel.name));
    setSelectedBu(business_units.map((bu) => bu.uuid));
  }, [active_channels, business_units]);

  useEffect(() => {
    let by_filter = {
      end_date: date?.end_date,
      start_date: date?.start_date,
    };
    if (date?.start_date && date?.end_date) {
      dispatch(getInteractionsCountByHowSolved(by_filter));
      dispatch(getInteractionsCountByWorkload(by_filter));
      dispatch(getSavingsChart(by_filter));
      dispatch(getCountChart(by_filter));
    }
  }, [date?.start_date, date?.end_date]);

  useEffect(() => {
    if (date?.start_date && date?.end_date)
      if (howSolved.length > 0 || workload.length > 0) {
        let data = {
          start_date: date?.start_date,
          end_date: date.end_date,
          page_no: 1,
          page_size: 10,
          how_solved: howSolved,
          workload_type: workload,
          source_channel_names: sourceChannels,
        };
        setOpenTable(true);
        if (howSolved.length > 0 && workload.length === 0)
          dispatch(getInteractionsTableByHowSolved(data));
        if (howSolved.length === 0 && workload.length > 0) {
          dispatch(getInteractionsTableByWorkload(data));
          dispatch(getInteractionsSourceChannelByWorkload(data));
        }
      } else setOpenTable(false);
  }, [howSolved, workload]);
  useEffect(() => {
    if (date?.start_date && date?.end_date)
      if (howSolved.length > 0 || workload.length > 0) {
        let data = {
          start_date: date?.start_date,
          end_date: date.end_date,
          page_no: parseInt(pagination.page) + 1,
          page_size: pagination.pageSize,
          how_solved: howSolved,
          workload_type: workload,
          source_channel_names: sourceChannels,
        };
        setOpenTable(true);
        if (howSolved.length > 0 && workload.length === 0)
          dispatch(getInteractionsTableByHowSolved(data));
        if (howSolved.length === 0 && workload.length > 0) {
          dispatch(getInteractionsTableByWorkload(data));
        }
      } else setOpenTable(false);
  }, [pagination.page, pagination.pageSize]);

  useEffect(() => {
    if (workload.length > 0) {
      let data = {
        start_date: date?.start_date,
        end_date: date.end_date,
        page_no: 1,
        page_size: 10,
        workload_type: workload,
        source_channel_names: sourceChannels,
      };
      dispatch(getInteractionsTableByWorkload(data));
    }
  }, [sourceChannels]);
  const cols = useMemo(() =>
    interactions_table?.columns
      ? interactions_table?.columns?.map((column) => {
          if (column === "created_at") {
            return {
              accessorKey: column,
              header: interactions_table?.display_map[column],
              Cell: ({ cell }) => {
                const timestamp = cell.getValue();
                const formattedDate =
                  moment(timestamp).format("MMM DD YYYY HH:mm");

                return (
                  <div
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {formattedDate}
                  </div>
                );
              },
            };
          }
          return {
            accessorKey: column,
            header: interactions_table?.display_map[column],
            Cell: ({ cell }) => (
              <div
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {cell.getValue()}
              </div>
            ),
          };
        })
      : []
  );

  const rows = interactions_table?.data
    ? interactions_table?.data?.map((dataItem, index) => {
        const row = { id: dataItem.uuid };

        interactions_table?.columns?.forEach((column) => {
          row[column] = dataItem[column];
        });
        return row;
      })
    : [];

  return (
    <div
      className="leaderboardWrap"
      style={{ overflow: "scroll", height: "calc(100vh - 70px)" }}
    >
      <Container maxWidth="xl">
        <div className="mainTitleWrap">
          <div className="mainTitle">
            <Typography variant="h4" sx={{ mb: 2 }}>
              Leaderboard
            </Typography>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Track Your Progress and Reclaim the best customer experience!
            </Typography>
          </div>
          <div className="dropdownWrap" style={{ position: "relative" }}>
            {/* <Grid width="185px">
              <MyMultiSelect
                options={memoizedActivChannels}
                label="Channels"
                defaultSelectAll={true}
                handleChange={handleChannelChange}
              />
            </Grid>
            <Grid width="185px">
              <MyMultiSelect
                options={memoizedBusinessUnits}
                label="Business Units"
                defaultSelectAll={true}
                handleChange={handleBuChange}
              />
            </Grid> */}
            <Grid>
              <Button
                fullWidth
                onClick={() => setOpenDate(true)}
                variant="ascendo"
                endIcon={<CalendarMonth />}
                sx={{
                  textTransform: "none",
                  textAlign: "left",
                  color: "grey",
                  borderColor: "grey",
                  border: "1px solid grey",
                  backgroundColor: "transparent",
                }}
              >
                {dateLabel}
              </Button>
            </Grid>
            <Grid
              display={openDate ? "block" : "none"}
              sx={{ position: "absolute", zIndex: 2, top: 0, right: 0 }}
              onMouseLeave={() => setOpenDate(false)}
            >
              <Card elevation={10}>
                <MyDateRangePicker onStateChange={handleDateChange} />
              </Card>
            </Grid>
          </div>
        </div>

        {interactions_count && (
          <Grid container spacing={2}>
            <Grid xs={12} className="cardGrid">
              <Card className="commonCard highlightWrap">
                <Typography className="highlightGreen">
                  {/* You are getting efficient */}
                </Typography>
                <Typography className="highlightTitle">Auto Solved</Typography>
                <Divider />
                <div className="innerWrap">
                  <div className="highlightNum">
                    {interactions_count["Auto Solved"]}
                  </div>
                  <div className="highlightText">
                    Rewards the knowledge creators to make CSAT top notch.
                  </div>
                </div>
                <Divider />
                <div className="buttonWrap">
                  <Button
                    variant="ascendo"
                    endIcon={<EastIcon />}
                    onClick={() => setHowSolved("Auto Solved")}
                  >
                    Reveal
                  </Button>
                </div>
              </Card>
            </Grid>

            <Grid xs={12} className="cardGrid">
              <Card className="commonCard highlightWrap">
                <Typography className="highlightGreen">
                  {/* You are getting efficient */}
                </Typography>
                <Typography className="highlightTitle">Agent Solved</Typography>
                <Divider />
                <div className="innerWrap">
                  <div className="highlightNum">
                    {interactions_count["Agent Solved"]}
                  </div>
                  <div className="highlightText">
                    To save costs or improve efficiency, should they be auto
                    solved instead?
                  </div>
                </div>
                <Divider />
                <div className="buttonWrap">
                  <Button
                    variant="ascendo"
                    endIcon={<EastIcon />}
                    onClick={() => setHowSolved("Agent Solved")}
                  >
                    Reveal
                  </Button>
                </div>
              </Card>
            </Grid>

            <Grid xs={12} className="cardGrid">
              <Card className="commonCard highlightWrap">
                <Typography className="highlightRed">
                  {/* You are getting efficient */}
                </Typography>
                <Typography className="highlightTitle">Manual</Typography>
                <Divider />
                <div className="innerWrap">
                  <div className="highlightNum">
                    {interactions_count["Manual"]}
                  </div>
                  <div className="highlightText">
                    Here are your gaps in automation that needs attention{" "}
                  </div>
                </div>
                <Divider />
                <div className="buttonWrap">
                  <Button
                    variant="ascendo"
                    endIcon={<EastIcon />}
                    onClick={() => setHowSolved("Manual")}
                  >
                    Reveal
                  </Button>
                </div>
              </Card>
            </Grid>

            <Grid xs={12} className="cardGrid">
              <Card className="commonCard highlightWrap">
                <Typography className="highlightGreen">
                  {/* You are getting efficient */}
                </Typography>
                <Typography className="highlightTitle">Not Solved</Typography>
                <Divider />
                <div className="innerWrap">
                  <div className="highlightNum">
                    {interactions_count["Not Solved"]}
                  </div>
                  <div className="highlightText">
                    Attend first to move to proactive support and reduce Churn{" "}
                  </div>
                </div>
                <Divider />
                <div className="buttonWrap">
                  <Button
                    variant="ascendo"
                    endIcon={<EastIcon />}
                    onClick={() => setHowSolved("Not Solved")}
                  >
                    Reveal
                  </Button>
                </div>
              </Card>
            </Grid>
          </Grid>
        )}

        <Grid container spacing={2} sx={{ my: "10px" }}>
          <Grid item xs={12} md={6} lg={6}>
            <Card className="commonCard chartCard">
              <div className="chartTitle">
                <Typography>Savings</Typography>
              </div>
              {savings_chart_details && (
                <Linecharts value={savings_chart_details} />
              )}
              {/* <div className="chartBtnwrap">
                <p className="greenText">
                  You have saved <span className="blueText">$ 60</span>
                </p>
                <Button
                  variant="ascendo"
                  className="chartBtn"
                  endIcon={<EastIcon />}
                  onClick={() => setHowSolved("Manual")}
                >
                  Reveal
                </Button>
              </div> */}
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Card className="commonCard chartCard">
              <div className="chartTitle">
                <Typography>Interactions overtime</Typography>
              </div>
              {count_chart_details && <Barcharts value={count_chart_details} />}

              {/* <div className="chartBtnwrap">
                <p className="redText">
                  Increase auto solve for more efficient{" "}
                </p>
                <Button
                  variant="ascendo"
                  className="chartBtn"
                  endIcon={<EastIcon />}
                  onClick={() => setHowSolved("Manual")}
                >
                  Reveal
                </Button>
              </div> */}
            </Card>
          </Grid>
        </Grid>
        {interactions_count_by_workload && (
          <Grid container spacing={2} mt="8px">
            <Grid xs={12} className="cardGrid">
              <Card className="commonCard highlightWrap">
                <Typography className="highlightGreen">
                  {/* You are getting efficient */}
                </Typography>
                <Typography className="highlightTitle">New Tickets</Typography>
                <Divider />
                <div className="innerWrap">
                  <div className="highlightNum">
                    {interactions_count_by_workload["New Tickets"]}
                  </div>
                  <div className="highlightText">
                  New Tickets that were created during this time period.                  </div>
                </div>
                <Divider />
                <div className="buttonWrap">
                  <Button
                    variant="ascendo"
                    endIcon={<EastIcon />}
                    onClick={() => setWorkload("New Tickets")}
                  >
                    Reveal
                  </Button>
                </div>
              </Card>
            </Grid>

            <Grid xs={12} className="cardGrid">
              <Card className="commonCard highlightWrap">
                <Typography className="highlightGreen">
                  {/* You are getting efficient */}
                </Typography>
                <Typography className="highlightTitle">
                  Backlog Tickets
                </Typography>
                <Divider />
                <div className="innerWrap">
                  <div className="highlightNum">
                    {interactions_count_by_workload["Backlog Tickets"]}
                  </div>
                  <div className="highlightText">
                  Open Tickets that are carried forward to this time period.
                  </div>
                </div>
                <Divider />
                <div className="buttonWrap">
                  <Button
                    variant="ascendo"
                    endIcon={<EastIcon />}
                    onClick={() => setWorkload("Backlog Tickets")}
                  >
                    Reveal
                  </Button>
                </div>
              </Card>
            </Grid>

            <Grid xs={12} className="cardGrid">
              <Card className="commonCard highlightWrap">
                <Typography className="highlightRed">
                  {/* You are getting efficient */}
                </Typography>
                <Typography className="highlightTitle">
                  Total Incoming Tickets
                </Typography>
                <Divider />
                <div className="innerWrap">
                  <div className="highlightNum">
                    {interactions_count_by_workload["Total Incoming Tickets"]}
                  </div>
                  <div className="highlightText">
                  Total Tickets that need to be solved in this time period.                  </div>
                </div>
                <Divider />
                <div className="buttonWrap">
                  <Button
                    variant="ascendo"
                    endIcon={<EastIcon />}
                    onClick={() => setWorkload("Total Incoming Tickets")}
                  >
                    Reveal
                  </Button>
                </div>
              </Card>
            </Grid>

            <Grid xs={12} className="cardGrid">
              <Card className="commonCard highlightWrap">
                <Typography className="highlightGreen">
                  {/* You are getting efficient */}
                </Typography>
                <Typography className="highlightTitle">
                  Solved Tickets
                </Typography>
                <Divider />
                <div className="innerWrap">
                  <div className="highlightNum">
                    {interactions_count_by_workload["Solved Tickets"]}
                  </div>
                  <div className="highlightText">
                  Tickets that were solved during this time period.                   </div>
                </div>
                <Divider />
                <div className="buttonWrap">
                  <Button
                    variant="ascendo"
                    endIcon={<EastIcon />}
                    onClick={() => setWorkload("Solved Tickets")}
                  >
                    Reveal
                  </Button>
                </div>
              </Card>
            </Grid>
          </Grid>
        )}
        {/*  {usagestats_details && (
            <Grid xs={12} md={6} lg={6}>
              <Card className="commonCard chartCard">
                <div className="chartTitle">
                  <Typography>Interactions overtime</Typography>
                </div>

                <Barcharts
                  value={usagestats_details["Interactions Over Time"]}
                />
              </Card>
            </Grid>
          )} 

           <Grid xs={12} md={6} lg={6}>
            <Card className="commonCard chartCard">
              <div className="chartTitle">
                <Typography>Customer Satisfaction Score</Typography>
              </div>

              {/* <TabMenu /> 
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} sm={6} className="chartLeftwrap">
                  <DonutCharts />
                </Grid>
                <Grid item xs={12} md={6} sm={6} className="chartRightwrap">
                  <div className="chartrightInner">
                    <JustIcon />
                    <Typography className="desc">worst - 38</Typography>
                    <DownIcon />
                    <Typography className="percentage redTxt">12%</Typography>
                  </div>
                  <div className="chartrightInner">
                    <JustIcon />
                    <Typography className="desc">Okay - 76</Typography>
                    <UpIcon />
                    <Typography className="percentage greenTxt">12%</Typography>
                  </div>
                  <div className="chartrightInner">
                    <JustIcon />
                    <Typography className="desc">Awesome - 186</Typography>
                    <UpIcon />
                    <Typography className="percentage greenTxt">12%</Typography>
                  </div>
                </Grid>
              </Grid>
            </Card>
          </Grid> 
          {/* {customer_effort_details && (
            <Grid xs={12} md={6} lg={6}>
              <Card className="commonCard chartCard">
                <div className="chartTitle">
                  <Typography>Customer Effort Score</Typography>
                </div>
                {/* <TabMenu /> 
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} sm={6} className="chartLeftwrap">
                    <SemiPie
                      value={customer_effort_details["Customer Effort Score"]}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={6} className="chartRightwrap">
                    <div className="chartrightInner">
                      <JustIcon />
                      <Typography className="desc smallTxt">
                        Low effort (0- 2) 30%
                      </Typography>
                      <DownIcon />
                      <Typography className="percentage redTxt">12%</Typography>
                    </div>
                    <div className="chartrightInner">
                      <JustIcon />
                      <Typography className="desc smallTxt">
                        Medium effort(3- 4) 40%
                      </Typography>
                      <UpIcon />
                      <Typography className="percentage greenTxt">
                        12%
                      </Typography>
                    </div>
                    <div className="chartrightInner">
                      <JustIcon />
                      <Typography className="desc smallTxt">
                        High effort(5- 6) 40%
                      </Typography>
                      <UpIcon />
                      <Typography className="percentage greenTxt">
                        12%
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          )} */}
        <Drawer
          sx={{
            "& .MuiPaper-root.MuiDrawer-paper ": {
              marginTop: "64px",
              width: "calc(100% - 300px)",
              padding: "16px",
            },
          }}
          anchor={"right"}
          open={openTable}
          onClose={() => {
            setOpenTable(false);
            setHowSolved("");
            setWorkload("");
            setPagination({ page: 0, pageSize: 10 });
            setSourceChannels([]);
          }}
        >
          {workload.length > 0 &&
            howSolved.length === 0 &&
            source_channel_count && (
              <Grid container spacing={2} className="channelWrap">
                {Object.keys(source_channel_count).map((item) => (
                  <Grid
                    item
                    xs={12}
                    md={3}
                    sm={3}
                    className="cardGrid"
                    onClick={() =>
                      setSourceChannels((prevSelected) => {
                        if (prevSelected.includes(item)) {
                          // Remove channel from array
                          return prevSelected.filter(
                            (oldItem) => oldItem !== item
                          );
                        } else {
                          // Add channel to array
                          return [...prevSelected, item];
                        }
                      })
                    }
                  >
                    <Card
                      className="commonCard highlightWrap"
                      sx={{
                        cursor: "pointer",
                        background: sourceChannels.includes(item)
                          ? "linear-gradient(180deg, rgba(26, 115, 232, 0.16) 0%, rgba(255, 255, 255, 0) 100%)"
                          : "transparent",
                        "&:hover": {
                          background: sourceChannels.includes(item)
                            ? "linear-gradient(180deg, rgba(26, 115, 232, 0.16) 0%, rgba(255, 255, 255, 0) 100%)"
                            : "transparent",
                          borderColor: sourceChannels.includes(item)
                            ? "linear-gradient(180deg, rgba(26, 115, 232, 0.16) 0%, rgba(255, 255, 255, 0) 100%)"
                            : "rgba(26, 115, 232, 0.5)",
                          boxShadow: "0px 4px 32px rgba(26, 115, 232, 0.12)",
                        },
                        borderColor: sourceChannels.includes(item)
                          ? colors.ascendo.blue
                          : colors.grey.normal,
                      }}
                    >
                      <div className="innerWrap">
                        <div className="textWrap">
                          <Typography className="numberTxt">
                            {source_channel_count[item]}
                          </Typography>
                          <Typography className="smallTxt">{item}</Typography>
                        </div>
                        <div className="imageWrap">
                     {renderIcon(item)}
                      
                        </div>
                      </div>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            )}
          <div className="fixedWrap">
            <Tooltip title="Close">
            <IconButton
              className="closeIcon"
              sx={{ position: "fixed", right: "0px", top: "67px" }}
              onClick={() => {
                setOpenTable(false);
                setHowSolved("");
                setWorkload("");
                setPagination({ page: 0, pageSize: 10 });
                setSourceChannels([]);
              }}
            >
              <Close sx={{ fontSize: "large" }} />
            </IconButton>
            </Tooltip>
            <div className="exportBtnWrap">
              <Typography
                className="primaryText boldText"
                sx={{ fontSize: "16px", fontWeight: "600", mb: "18px" }}
                color={colors.ascendo.darkblue}
              >
                {`Interactions - ${
                  howSolved.length > 0 ? howSolved : workload
                }`}
              </Typography>
              <Tooltip title="Export">
              <IconButton
                className="closeIcon1"
                onClick={() => {
                  let data = {
                    start_date: date?.start_date,
                    end_date: date?.end_date,
                    how_solved: howSolved,
                    workload_type: workload,
                  };
                  if (howSolved.length > 0) {
                    dispatch(exportInteractionsTableByHowSolved(data));
                  } else if (workload.length > 0) {
                    dispatch(exportInteractionsTableByWorkload(data))
                  }
                }}
              >
                
                <DowloadIcon/>
                
              </IconButton> 
              </Tooltip>
            </div>
          </div>
          <div className={howSolved.length>0?"tableFixedWrapSolvedBy":"tableFixedWrap"}>
          <ResizableTable
            data={rows}
            columns={cols}
            paginationDetails={pagination}
            onTablePaginationChange={(newValue) => setPagination(newValue)}
            rowCount={interactions_table?.total ?? 0}
            onTableRowClick={() => {}}
          />
          </div>
        </Drawer>
      </Container>
    </div>
  );
}

export default Leaderboardview;
