import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Settings from "@mui/icons-material/Settings";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";
import StatusOffline from "../../assets/images/status_appear_offline.svg";
import StatusAvailable from "../../assets/images/status_available.svg";
import StatusAway from "../../assets/images/status_away.svg";
import StatusBusy from "../../assets/images/status_busy.png";
import StatusDND from "../../assets/images/status_do_not_disturb.png";


import ProfileIcon from "../../assets/profileIcon";
import "./sidenav.scss";

import { useTheme } from "@emotion/react";
import {
  Close,
  DeleteOutline,
  Home
} from "@mui/icons-material";
import SettingsIcon from "@mui/icons-material/Settings";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AscendoIcon from "../../assets/AscendoIcon";
import { getLogInfo } from "../../redux/slices/authenticationSlice";


import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  SwipeableDrawer,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import HelpIcon from "../../assets/HelpIcon";
import LeaderboardIcon from "../../assets/leaderboardIcon";
import LogoutIcon from "../../assets/LogoutIcon";

import { getAllModule } from "../../redux/slices/leaderboardSlice";
import {
  deleteCustomer,
  getAllAvailability,
} from "../../redux/slices/sharedComponents/sharedComponentsSlice";

const drawerWidth = 200;

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string?.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function transform(value) {
  if (value) {
    const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
    if (seconds < 29) {
      return "Just now";
    }

    const intervals = {
      year: 31536000,
      month: 2592000,
      week: 604800,
      day: 86400,
      hour: 3600,
      minute: 60,
      second: 1,
    };

    let counter;
    for (const i in intervals) {
      counter = Math.floor(seconds / intervals[i]);
      if (counter > 0) {
        if (counter === 1) {
          return counter + " " + i + " ago"; // singular (1 day ago)
        } else {
          return counter + " " + i + "s ago"; // plural (2 days ago)
        }
      }
    }
  }
  return value;
}
function stringAvatar(name) {
  let initials = name ? name.split(" ")[0][0] : ""; // First initial

  // Add second initial if it exists
  if (name && name.split(" ")[1]) {
    initials += name.split(" ")[1][0];
  }

  return {
    sx: {
      bgcolor: stringToColor(name),
      width: 32,
      height: 32,
      fontSize: 14,
    },
    children: initials,
  };
}

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    //width: `calc(100% - ${drawerWidth}px)`,
    width: `calc(100% - 0)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
export default function MiniDrawer() {
  const theme = useTheme();
  const colors = theme.palette;
  const [open, setOpen] = useState(true);
  const { availabilities, notifications } = useSelector(
    (state) => state.sharedComponentReducer
  );
  const [iframeOpen, setIframeOpen] = useState(false);
  const navigate = useNavigate();
  const currentUrl = window.location.href;
  const dispatch = useDispatch();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  useEffect(() => {
    dispatch(getLogInfo("log"));
  }, []);

  // Split the URL by '/'
  const urlParts = currentUrl.split("/");

  const currentPage = urlParts[4];

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  
  const [anchorEl, setAnchorEl] = useState(null);
  const open1 = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const userDetails = JSON.parse(localStorage.getItem("ud"));
  const customer_uuid = localStorage.getItem("ci");
  const user_uuid = localStorage.getItem("ui");
  const [availability, setAvailability] = useState(
    userDetails?.availability ?? ""
  );
 



  const [anchorEle, setAnchorEle] = React.useState(null);

 

  const openpop = Boolean(anchorEle);
  const id = openpop ? "simple-popover" : undefined;

  const { userdetails } = useSelector((state) => state.authenticationReducer);

  useEffect(() => {
    if (customer_uuid) {
      dispatch(getAllModule(customer_uuid));
    }
    dispatch(getAllAvailability());
  }, [customer_uuid, user_uuid]);

  useEffect(() => {
    if (userdetails?.availability) {
      setAvailability(userdetails?.availability);
    }
  }, [userdetails?.availability]);



  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <div className="headerWrap">
          <div className="headerLeftwrap">
            <Toolbar sx={{ display: "flex", flexDirection: "row" }}>

              

              <AscendoIcon />
            </Toolbar>
          </div>
          <div className="headerRight">
            
            {userDetails?.permissions?.includes("rp_help") && (
              <Tooltip title="Help">
                {" "}
                <div onClick={() => setIframeOpen(true)}>
                  <HelpIcon />
                </div>
              </Tooltip>
            )}
           

            {/* <SettingsIcon onClick={handleClick} size="small" sx={{ ml: 2 }} /> */}
          </div>

          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open1}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem
              className="menuWrap"
              onClick={() => {
                window.open(
                  process.env.REACT_APP_ADMIN +
                    `${localStorage.getItem(
                      "ci"
                    )}/validate/${localStorage.getItem(
                      "ui"
                    )}/${localStorage.getItem("at")}/${localStorage.getItem(
                      "rt"
                    )}`
                );
                handleClose();
              }}
            >
              <div className="settingWrap">
                <div className="imageWrap">
                  <ListItemIcon>
                    <Settings fontSize="small" />
                  </ListItemIcon>
                </div>

                <div className="textWrap">
                  <p> Administration</p>
                  <span className="smallText">
                    {" "}
                    Add users, groups, roles and manage access requests.{" "}
                  </span>
                </div>
              </div>
            </MenuItem>
            {userDetails?.permissions?.includes("super_admin") && (
              <MenuItem
                className="menuWrap"
                onClick={() => {
                  setDialogOpen(true);
                  handleClose();
                }}
              >
                <div className="settingWrap">
                  <div className="imageWrap">
                    <ListItemIcon>
                      <DeleteOutline fontSize="small" />
                    </ListItemIcon>
                  </div>

                  <div className="textWrap">
                    <p> Delete Customer</p>
                    <span className="smallText">
                      {" "}
                      Deleting this customer will erase all the data and remove
                      accounts added.{" "}
                    </span>
                  </div>
                </div>
              </MenuItem>
            )}
          </Menu>
        </div>
        <div className="headerRightwrap">
          <Box sx={{ width: "5%" }}></Box>
        </div>
      </AppBar>

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the customer?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ pr: 3, pb: 2.5 }}>
          <Button
            variant="ascendo"
            width="75px"
            sx={{
              color: "white",
              backgroundColor: "red",
              "&:hover": {
                color: "rgba(7, 25, 66)",
              },
            }}
            onClick={() => setDialogOpen(false)}
          >
            Cancel
          </Button>
          <Button
            variant="ascendo"
            width="75px"
            onClick={() => {
              setDialogOpen(false);
              dispatch(deleteCustomer());
              window.location.href = `${process.env.REACT_APP_LOGIN}?redirect_url=${process.env.REACT_APP_WP}`;
            }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <div className="outerDrawer">
        <div
          className="arrows"
          onClick={handleDrawerToggle}
          sx={{ marginRight: 5, ...(open && { display: "block" }) }}
        >
          {" "}
          <Tooltip title={open ? "Collapse" : "Expand"}>
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </Tooltip>
        </div>
        <Drawer variant="permanent" open={open} className="menuWrap">
          <DrawerHeader></DrawerHeader>

          <Divider />
          <List>
            <mat-card class="mat-card mat-focus-indicator user-details-card w-100">
              <mat-card-header class="mat-card-header ng-star-inserted">
                <div mat-card-avatar="" class="mat-card-avatar header-image">
                  <ProfileIcon />
                </div>

                <div class="mat-card-header-text">
                  <mat-card-title
                    class="mat-tooltip-trigger mat-card-title username"
                    aria-describedby="cdk-describedby-message-13"
                    cdk-describedby-host=""
                  >
                    {userDetails?.username ?? userdetails?.username}
                  </mat-card-title>
                  <mat-card-subtitle class="mat-card-subtitle orgname">
                    {userDetails?.customer?.name ?? userdetails?.customer?.name}
                  </mat-card-subtitle>
                </div>
              </mat-card-header>
            </mat-card>
          </List>
          {currentPage !== "support-channel" && (
            <List>
              <ListItem
                disablePadding
                sx={{ display: "block" }}
                onClick={() => navigate(`/${localStorage.getItem("ci")}/home`)}
              >
                <ListItemButton
                  className="menuList"
                  sx={{
                    justifyContent: open ? "initial" : "center",
                    px: 2,
                    color: colors.ascendo.darkblue,
                    backgroundColor:
                      currentPage === "home"
                        ? colors.ascendo.yellow
                        : "transparent",
                    "&:hover": {
                      backgroundColor:
                        currentPage === "home"
                          ? colors.ascendo.yellow
                          : "transparent",
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Home sx={{color:colors.ascendo.darkblue,opacity:0.8}}/>
                  </ListItemIcon>
                  <ListItemText
                    primary={"Home"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem
                  disablePadding
                  sx={{ display: "block" }}
                  onClick={() =>
                    navigate(`/${localStorage.getItem("ci")}/leaderboard`)
                  }
                >
                  <ListItemButton
                    className="menuList"
                    sx={{
                      justifyContent: open ? "initial" : "center",
                      px: 2,
                      color: colors.ascendo.darkblue,
                      backgroundColor:
                        currentPage === "leaderboard"
                          ? colors.ascendo.yellow
                          : "transparent",
                      "&:hover": {
                        backgroundColor:
                          currentPage === "leaderboard"
                            ? colors.ascendo.yellow
                            : "transparent",
                      },
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <LeaderboardIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Leaderboard"}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              
            </List>
          )}

          <List sx={{ height: "100vh" }}>
            {["Logout"].map((text, index) => (
              <ListItem
                onClick={() => navigate("/logout")}
                key={text}
                disablePadding
                sx={{ display: "block", position: "absolute", bottom: "10px" }}
              >
                <Divider className="borderBottom" />
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {index % 2 === 0 ? <LogoutIcon /> : <LogoutIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
        {iframeOpen && (
          <div className="helpAibot">
            <SwipeableDrawer
              anchor={"right"}
              open={iframeOpen}
              sx={{ zIndex: "9999" }}
              onClose={() => setIframeOpen(false)}
            >
              <div className="aibotClose" onClick={() => setIframeOpen(false)}>
                <Close />
              </div>
              <iframe
                style={{ height: "100vh", width: "465px" }}
                src={`https://support.aibot.ascendo.ai/?api_key=7fa76852-1c8f-4c57-b9d7-28f3cfc6307f&user_email=${userDetails?.email}&username=${userDetails?.username}`}
              ></iframe>
            </SwipeableDrawer>
          </div>
        )}
        
      </div>
    </Box>
  );
}
