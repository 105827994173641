import { useTheme } from "@emotion/react";
import {
  Analytics,
  LegendToggle,
  PrivacyTip,
  Settings,
} from "@mui/icons-material";
import { Card, Grid, Tooltip } from "@mui/material";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AgentRoutingIcon from "../../assets/AgentRoutingIcon";
import SparesLargeIcon from "../../assets/SparesLargeIcon";
import VocLargeIcon from "../../assets/VocLargeIcon";
import { getUserInfo } from "../../redux/slices/authenticationSlice";
import { getAllModule } from "../../redux/slices/leaderboardSlice";
import "./homeview.scss";
import AiresolveIcon from "../../assets/airesolveIcon";

function HomeView() {
  const theme = useTheme();
  const colors = theme.palette;
  const dispatch = useDispatch();
  const { userdetails } = useSelector((state) => state.authenticationReducer);
  const { moduledetails } = useSelector((state) => state.leaderboardReducer);
  const userDetails = JSON.parse(localStorage.getItem("ud"));
  useEffect(() => {
    if (!localStorage.getItem("ci"))
      window.location.href = `${process.env.REACT_APP_LOGIN}?redirect_url=${window.location.origin}`;
    else {
      dispatch(getUserInfo());
      dispatch(getAllModule(localStorage.getItem("ci")));
    }
  }, []);

  return (
    <div
      className="homeWrap"
      style={{
        overflow: "scroll",
        height: "calc(100vh - 70px)",
        marginTop: "5px",
      }}
    >
      <Container maxWidth="xl">
        <div className="mainTitleWrap">
          <div className="mainTitle">
            <Typography variant="h4" sx={{ mb: 2 }}>
              Home
            </Typography>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Welcome to Ascendo! Support beyond Expectations.
            </Typography>
          </div>
        </div>
        <Grid ml="20px">
          <Typography
            sx={{
              color: colors.ascendo.blue,
              my: "18px",
              fontWeight: "600",
              fontSize: "24px",
            }}
          >
            G'day, {userDetails?.username ?? userdetails?.username}
          </Typography>
          <Typography
            sx={{
              color: colors.ascendo.darkblue,
              mb: "16px",
              fontWeight: "600",
            }}
          >
            Your apps
          </Typography>
          <Grid container spacing={3}>
            {(userdetails?.permissions?.includes("super_admin") || userdetails?.permissions?.includes("admin")) && (
              <Grid
                item
                md={1.8}
                sm={4}
                xs={6}
                xl={1.3}
                onClick={() =>
                  window.open(
                    process.env.REACT_APP_ADMIN +
                      `${localStorage.getItem(
                        "ci"
                      )}/validate/${localStorage.getItem(
                        "ui"
                      )}/${localStorage.getItem("at")}/${localStorage.getItem(
                        "rt"
                      )}`
                  )
                }
              >
                <Card
                  sx={{
                    p: "8px",
                    cursor: "pointer",
                    transition:
                      "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
                    "&:hover": {
                      // Light grey background on hover
                      boxShadow: "0px 4px 15px rgba(27,144,232, 0.6)", // Slight shadow effect
                      transform: "scale(1.05)", // Slight zoom effect
                    },
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#f5f5f5",
                      height: "100px",
                      borderRadius: "6px",
                    }}
                  >
                    <Settings
                      sx={{
                        color: "#071942",
                        opacity: "0.8",
                        fontSize: "44px",
                      }}
                    />
                  </Grid>
                  <Typography
                    sx={{
                      color: colors.ascendo.darkblue,
                      fontSize: "12px",
                      mt: "8px",
                      fontWeight: "600",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      width: "100%",
                    }}
                  >
                    Admin
                  </Typography>
                </Card>
              </Grid>
            )}
            <Tooltip
              title={
                moduledetails?.find((item) => item.name === "Agent Routing")
                  ?.is_active
                  ? ""
                  : "You are not subscribed to this module"
              }
            >
              <Grid
                item
                md={1.8}
                sm={4}
                xs={6}
                xl={1.3}
                onClick={() => {
                  if (
                    moduledetails?.find((item) => item.name === "Agent Routing")
                      ?.is_active
                  )
                    window.open(
                      `https://${userdetails?.customer?.name}.${
                        process.env.REACT_APP_RCA
                      }/${localStorage.getItem(
                        "ci"
                      )}/rca/${localStorage.getItem(
                        "at"
                      )}/${localStorage.getItem("rt")}`
                    );
                }}
              >
                <Card
                  sx={{
                    p: "8px",
                    cursor: moduledetails?.find(
                      (item) => item.name === "Agent Routing"
                    )?.is_active
                      ? "pointer"
                      : "default",
                      opacity: moduledetails?.find(
                        (item) => item.name === "Agent Routing"
                      )?.is_active
                        ? 1
                        : 0.4,
                    transition:
                      "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
                    "&:hover": moduledetails?.find(
                      (item) => item.name === "Agent Routing"
                    )?.is_active
                      ? {
                          // Light grey background on hover
                          boxShadow: "0px 4px 15px rgba(27,144,232, 0.6)", // Slight shadow effect
                          transform: "scale(1.05)", // Slight zoom effect
                        }
                      : {},
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#f5f5f5",
                      height: "100px",
                      borderRadius: "6px",
                    }}
                  >
                    <AgentRoutingIcon size="40" />
                  </Grid>
                  <Typography
                    sx={{
                      color: colors.ascendo.darkblue,
                      fontSize: "12px",
                      mt: "8px",
                      fontWeight: "600",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      width: "100%",
                    }}
                  >
                    Agent Routing
                  </Typography>
                </Card>
              </Grid>
            </Tooltip>
            <Tooltip
              title={
                moduledetails?.find((item) => item.name === "next_gen_spares")
                  ?.is_active
                  ? ""
                  : "You are not subscribed to this module"
              }
            >
              <Grid
                item
                md={1.8}
                sm={4}
                xs={6}
                xl={1.3}
                onClick={() => {
                  if (
                    moduledetails?.find(
                      (item) => item.name === "next_gen_spares"
                    )?.is_active
                  )
                    window.open(
                      `https://${userdetails?.customer?.name}.${
                        process.env.REACT_APP_SPARES
                      }/${localStorage.getItem(
                        "ci"
                      )}/validate/${localStorage.getItem(
                        "at"
                      )}/${localStorage.getItem("rt")}`
                    );
                }}
              >
                <Card
                  sx={{
                    p: "8px",
                    cursor: moduledetails?.find(
                      (item) => item.name === "next_gen_spares"
                    )?.is_active
                      ? "pointer"
                      : "default",
                      opacity: moduledetails?.find(
                        (item) => item.name === "next_gen_spares"
                      )?.is_active
                        ? 1
                        : 0.4,
                    transition:
                      "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
                    "&:hover": moduledetails?.find(
                      (item) => item.name === "next_gen_spares"
                    )?.is_active
                      ? {
                          // Light grey background on hover
                          boxShadow: "0px 4px 15px rgba(27,144,232, 0.6)", // Slight shadow effect
                          transform: "scale(1.05)", // Slight zoom effect
                        }
                      : {},
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#f5f5f5",
                      height: "100px",
                      borderRadius: "6px",
                    }}
                  >
                    <SparesLargeIcon />
                  </Grid>
                  <Typography
                    sx={{
                      color: colors.ascendo.darkblue,
                      fontSize: "12px",
                      mt: "8px",
                      fontWeight: "600",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      width: "100%",
                    }}
                  >
                    Cognititve Spares
                  </Typography>
                </Card>
              </Grid>
            </Tooltip>
            <Tooltip
              title={
                moduledetails?.find((item) => item.name === "log")?.is_active
                  ? ""
                  : "You are not subscribed to this module"
              }
            >
              <Grid
                item
                md={1.8}
                sm={4}
                xs={6}
                xl={1.3}
                onClick={() => {
                  if (
                    moduledetails?.find((item) => item.name === "log")
                      ?.is_active
                  )
                    window.open(
                      `${
                        process.env.REACT_APP_LOG
                      }validate-user/${localStorage.getItem(
                        "ci"
                      )}/${localStorage.getItem("li")}/${localStorage.getItem(
                        "at"
                      )}/${localStorage.getItem("rt")}`
                    );
                }}
              >
                <Card
                  sx={{
                    p: "8px",
                    cursor: moduledetails?.find((item) => item.name === "log")
                      ?.is_active
                      ? "pointer"
                      : "default",
                      opacity: moduledetails?.find((item) => item.name === "log")
                      ?.is_active
                      ? 1
                      : 0.4,
                    transition:
                      "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
                    "&:hover": moduledetails?.find(
                      (item) => item.name === "log"
                    )?.is_active
                      ? {
                          // Light grey background on hover
                          boxShadow: "0px 4px 15px rgba(27,144,232, 0.6)", // Slight shadow effect
                          transform: "scale(1.05)", // Slight zoom effect
                        }
                      : {},
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#f5f5f5",
                      height: "100px",
                      borderRadius: "6px",
                    }}
                  >
                    <LegendToggle
                      sx={{
                        color: colors.ascendo.darkblue,
                        opacity: 0.8,
                        fontSize: "40px",
                      }}
                    />
                  </Grid>
                  <Typography
                    sx={{
                      color: colors.ascendo.darkblue,
                      fontSize: "12px",
                      mt: "8px",
                      fontWeight: "600",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      width: "100%",
                    }}
                  >
                    Log Analysis
                  </Typography>
                </Card>
              </Grid>
            </Tooltip>

            <Grid
              item
              md={1.8}
              sm={4}
              xs={6}
              xl={1.3}
              onClick={() =>
                window.open(
                  `https://${
                    process.env.REACT_APP_PRIVACY_FILTER
                  }/${localStorage.getItem("ci")}/authentication/${localStorage.getItem(
                    "at"
                  )}/${localStorage.getItem("rt")}`
                )
              }
            >
              <Card
                sx={{
                  p: "8px",
                  cursor: "pointer",
                  transition:
                    "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
                  "&:hover": {
                    // Light grey background on hover
                    boxShadow: "0px 4px 15px rgba(27,144,232, 0.6)", // Slight shadow effect
                    transform: "scale(1.05)", // Slight zoom effect
                  },
                }}
              >
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#f5f5f5",
                    height: "100px",
                    borderRadius: "6px",
                  }}
                >
                  <PrivacyTip
                    sx={{ color: "#071942", opacity: "0.8", fontSize: "40px" }}
                  />
                </Grid>
                <Typography
                  sx={{
                    color: colors.ascendo.darkblue,
                    fontSize: "12px",
                    mt: "8px",
                    fontWeight: "600",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    width: "100%",
                  }}
                >
                  Privacy Filter
                </Typography>
              </Card>
            </Grid>
            <Tooltip
              title={
                moduledetails?.find(
                  (item) => item.name === "Resolution Prediction"
                )?.is_active
                  ? ""
                  : "You are not subscribed to this module"
              }
            >
              <Grid
                item
                md={1.8}
                sm={4}
                xs={6}
                xl={1.3}
                onClick={() => {
                  if (
                    moduledetails?.find(
                      (item) => item.name === "Resolution Prediction"
                    )?.is_active
                  )
                    window.open(
                      `https://${userdetails?.customer?.name}.${
                        process.env.REACT_APP_RP
                      }/${localStorage.getItem(
                        "ci"
                      )}/authentication/${localStorage.getItem(
                        "at"
                      )}/${localStorage.getItem("rt")}`
                    );
                }}
              >
                <Card
                  sx={{
                    p: "8px",
                    cursor: moduledetails?.find(
                      (item) => item.name === "Resolution Prediction"
                    )?.is_active
                      ? "pointer"
                      : "default",
                      opacity: moduledetails?.find(
                        (item) => item.name === "Resolution Prediction"
                      )?.is_active
                        ? 1
                        : 0.4,
                    transition:
                      "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
                    "&:hover": moduledetails?.find(
                      (item) => item.name === "Resolution Prediction"
                    )?.is_active
                      ? {
                          // Light grey background on hover
                          boxShadow: "0px 4px 15px rgba(27,144,232, 0.6)", // Slight shadow effect
                          transform: "scale(1.05)", // Slight zoom effect
                        }
                      : {},
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#f5f5f5",
                      height: "100px",
                      borderRadius: "6px",
                    }}
                  >
                    <AiresolveIcon size={"40"} />
                  </Grid>
                  <Typography
                    sx={{
                      color: colors.ascendo.darkblue,
                      fontSize: "12px",
                      mt: "8px",
                      fontWeight: "600",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      width: "100%",
                    }}
                  >
                    Resolution Prediction
                  </Typography>
                </Card>
              </Grid>
            </Tooltip>
            <Tooltip
              title={
                moduledetails?.find(
                  (item) => item.name === "Root Cause Analysis"
                )?.is_active
                  ? ""
                  : "You are not subscribed to this module"
              }
            >
              <Grid
                item
                md={1.8}
                sm={4}
                xs={6}
                xl={1.3}
                onClick={() => {
                  if (
                    moduledetails?.find(
                      (item) => item.name === "Root Cause Analysis"
                    )?.is_active
                  )
                    window.open(
                      `https://${userdetails?.customer?.name}.${
                        process.env.REACT_APP_RCA
                      }/${localStorage.getItem(
                        "ci"
                      )}/rca/${localStorage.getItem(
                        "at"
                      )}/${localStorage.getItem("rt")}`
                    );
                }}
              >
                <Card
                  sx={{
                    p: "8px",
                    cursor: moduledetails?.find(
                      (item) => item.name === "Root Cause Analysis"
                    )?.is_active
                      ? "pointer"
                      : "default",
                      opacity: moduledetails?.find(
                        (item) => item.name === "Root Cause Analysis"
                      )?.is_active
                        ? 1
                        : 0.4,
                    transition:
                      "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
                    "&:hover": moduledetails?.find(
                      (item) => item.name === "Root Cause Analysis"
                    )?.is_active
                      ? {
                          // Light grey background on hover
                          boxShadow: "0px 4px 15px rgba(27,144,232, 0.6)", // Slight shadow effect
                          transform: "scale(1.05)", // Slight zoom effect
                        }
                      : {},
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#f5f5f5",
                      height: "100px",
                      borderRadius: "6px",
                    }}
                  >
                    <Analytics
                      sx={{
                        color: "#071942",
                        opacity: "0.8",
                        fontSize: "40px",
                      }}
                    />
                  </Grid>
                  <Typography
                    sx={{
                      color: colors.ascendo.darkblue,
                      fontSize: "12px",
                      mt: "8px",
                      fontWeight: "600",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      width: "100%",
                    }}
                  >
                    Root Cause Analysis
                  </Typography>
                </Card>
              </Grid>
            </Tooltip>
            <Tooltip
              title={
                moduledetails?.find(
                  (item) => item.name === "Voice of the Customer"
                )?.is_active
                  ? ""
                  : "You are not subscribed to this module"
              }
            >
              <Grid
                item
                md={1.8}
                sm={4}
                xs={6}
                xl={1.3}
                onClick={() => {
                  if (
                    moduledetails?.find(
                      (item) => item.name === "Voice of the Customer"
                    )?.is_active
                  )
                    window.open(
                      `https://${userdetails?.customer?.name}.${
                        process.env.REACT_APP_VOC
                      }/${localStorage.getItem(
                        "ci"
                      )}/trending/${localStorage.getItem(
                        "at"
                      )}/${localStorage.getItem("rt")}`
                    );
                }}
              >
                <Card
                  sx={{
                    p: "8px",
                    cursor: moduledetails?.find(
                      (item) => item.name === "Voice of the Customer"
                    )?.is_active
                      ? "pointer"
                      : "default",
                      opacity: moduledetails?.find(
                        (item) => item.name === "Voice of the Customer"
                      )?.is_active
                        ? 1
                        : 0.4,
                    transition:
                      "transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out",
                    "&:hover": moduledetails?.find(
                      (item) => item.name === "Voice of the Customer"
                    )?.is_active
                      ? {
                          // Light grey background on hover
                          boxShadow: "0px 4px 15px rgba(27,144,232, 0.6)", // Slight shadow effect
                          transform: "scale(1.05)", // Slight zoom effect
                        }
                      : {},
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#f5f5f5",
                      height: "100px",
                      borderRadius: "6px",
                    }}
                  >
                    <VocLargeIcon />
                  </Grid>
                  <Typography
                    sx={{
                      color: colors.ascendo.darkblue,
                      fontSize: "12px",
                      mt: "8px",
                      fontWeight: "600",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      width: "100%",
                    }}
                  >
                    Voice of the Customer
                  </Typography>
                </Card>
              </Grid>
            </Tooltip>
          </Grid>
        </Grid>
        {/* <Grid container spacing={2}>

          <Grid item xs={12} md={12} sm={12} className="cardGrid">
            <Card className="commonCard">
              <Grid item xs={12} md={6} sm={6} className='flexCenter'>
                <div>
                <Typography className='titleblack'>
                  Unlock Modern <span className='titleblue'>Support</span><br /> Experience
                </Typography>
                <Typography className='smallText'>
                  Get ready to resolve questions and issues using Ascendo's predictive algorithms. Tackle problems proactively by addressing them even before they emerge.
                </Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={6} sm={6} className='flexCenter imageWrap'>
                <img src={homeIcon} />
              </Grid>
            </Card>

          </Grid>

        </Grid> */}
      </Container>
    </div>
  );
}

export default HomeView;
